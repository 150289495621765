<div class="footer-nav d-flex no-gutters">
  <a
    matRipple
    class="footer-nav-item col"
    routerLinkActive="active"
    [routerLink]="['/livescore']"
  >
    <img src="assets/img/footer_nav_1.png" class="footer-nav-item-icon-1" />
    <div class="w-100" i18n>მატჩები</div>
  </a>
  <a
    matRipple
    class="footer-nav-item col"
    routerLinkActive="active"
    [routerLink]="['/cxrilebi']"
  >
    <img src="assets/img/footer_nav_2.png" class="footer-nav-item-icon-2" />
    <div class="w-100" i18n>ცხრილები</div>
  </a>
  <a
    matRipple
    class="footer-nav-item col"
    routerLinkActive="active"
    [routerLink]="['/videos/1']"
  >
    <img src="assets/img/footer_nav_3.png" class="footer-nav-item-icon-3" />
    <div class="w-100" i18n>ვიდეოები</div>
  </a>
  <a
    matRipple
    class="footer-nav-item col"
    routerLinkActive="active"
    [routerLink]="['/category/3/transferebi/1']"
  >
    <img src="assets/img/footer_nav_4.png" class="footer-nav-item-icon-4" />
    <div class="w-100" i18n>ტრანსფერები</div>
  </a>
</div>
