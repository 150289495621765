import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  private environment: any;

  constructor(private meta: Meta, @Inject('env') env: any) {
    this.environment = env;
  }

  generateTags(config): void {
    // default values
    const tempConfig = {
      title: $localize`Goal.Ge - ფეხბურთი`,
      description: $localize`Goal.Ge - ფეხბურთი`,
      image: `${this.environment.domain}/assets/img/share_image.png`,
      slug: this.environment.domain,
      ...config,
    };
    this.setMetaTag('twitter:title', tempConfig?.title);
    this.setMetaTag('twitter:card', tempConfig?.image);
    this.setMetaTag('twitter:site', this.environment?.domain);
    this.setMetaTag('twitter:description', tempConfig?.description);
    this.setMetaTag('twitter:image', tempConfig?.image);
    this.setMetaTag('description', tempConfig?.description);
    this.setMetaTag('og:locale', 'ka_GE');
    this.setMetaTag('og:type', 'article');
    this.setMetaTag('og:site_name', tempConfig?.title);
    this.setMetaTag('inst:title', tempConfig?.title);
    this.setMetaTag('og:title', tempConfig?.title);
    this.setMetaTag('og:description', tempConfig?.description);
    this.setMetaTag('og:image', tempConfig?.image);
    this.setMetaTag('og:url', `${this.environment.domain}/${tempConfig.slug}`);
  }


  setMetaTag(tag: string, content: string): void{
    try{
      const meta = this.meta.getTag(`property='${tag}'`);
      if(meta && content){
        this.meta.updateTag({ property: tag, content });
      }
    }catch(e){}
  }
}
