import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from '@goal-front/shared';

const alternate = [
  {
    name: 'hi',
    prefix: ``,
  },
  {
    name: 'x-default',
    prefix: ``,
  },
];

@Injectable({
  providedIn: 'root',
})
export class CanonicalService {
  pagerConfig: Subject<{ prev: number; next: number }> = new Subject();
  private url: string;
  constructor(
    @Inject(DOCUMENT) private dom,
    private readonly destroy$: DestroyService
  ) {
    this.setPager();
  }

  updateCanonicalUrl(path: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    const domain = window.location.origin;
    const url = domain + path;
    var element: HTMLLinkElement =
      this.dom.querySelector(`link[rel='canonical']`) || null;
    if (!element) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);

    alternate.forEach((item) => {
      let elementAlt: HTMLLinkElement =
        this.dom.querySelector(`link[hreflang='${item.name}']`) || null;
      if (!elementAlt) {
        elementAlt = this.dom.createElement('link') as HTMLLinkElement;
        head.appendChild(elementAlt);
      }
      elementAlt.setAttribute('rel', 'alternate');
      elementAlt.setAttribute('hreflang', item.name);
      elementAlt.setAttribute('href', domain + item.prefix + path);
    });

    this.url = url;
  }

  setPager(): void {
    this.pagerConfig.pipe(takeUntil(this.destroy$)).subscribe({
      next: (v) => {
        if (!v) {
          return;
        }

        const head = this.dom.getElementsByTagName('head')[0];
        var elementPrev: HTMLLinkElement =
          this.dom.querySelector(`link[rel='prev']`) || null;
        var elementNext: HTMLLinkElement =
          this.dom.querySelector(`link[rel='next']`) || null;
        if (!v.prev && elementPrev) {
          elementPrev.remove();
        }
        if (!v.next && elementNext) {
          elementNext.remove();
        }
        if (v.prev) {
          if (!elementPrev) {
            elementPrev = this.dom.createElement('link') as HTMLLinkElement;
            head.appendChild(elementPrev);
          }
          elementPrev.setAttribute('rel', 'prev');
          elementPrev.setAttribute('href', `${this.url}/${v?.prev}`);
        }
        if (v.next) {
          if (!elementNext) {
            elementNext = this.dom.createElement('link') as HTMLLinkElement;
            head.appendChild(elementNext);
          }
          elementNext.setAttribute('rel', 'next');
          elementNext.setAttribute('href', `${this.url}/${v?.next}`);
        }
      },
    });
  }
}
