// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api: $localize`https://api.goal.ge/api/`,
  api: $localize`https://api.goal.ge/api/`,
  storage: $localize`//storage.goal.ge`,
  storage_old: $localize`//storage.goal.ge/uploads/`,
  storage_video: $localize`//storage.setantamedia.ge/`,
  storageurlResizerPoster: $localize`//storage.goal.ge/size/timthumb.php?src=/uploads/posts/`,
  articleAttachedImage: $localize`//storage.goal.ge/uploads/posts/`,
  domain: $localize`https://goal.ge`,
  livescore_refresh_seconds: 60,
  match_refresh_seconds: 60,
  port: 4004,
  alternate: $localize`https://goal.ge`,
  redisPrefix: 'dev:mobile-pages:',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
