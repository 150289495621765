import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SearchComponent } from './search/search.component';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '@goal-front/core';
import { SharedModule } from '@goal-front/shared';
import { NavbarStore } from '../../stores/navbar.store';
import { ModalService } from '../../services/modal.service';
import { SearchMobileComponent } from './search-mobile/search-mobile.component';
import { MobileNavbarComponent } from './mobile-navbar/mobile-navbar.component';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  declarations: [
    HeaderComponent,
    NavbarComponent,
    SearchComponent,
    SearchMobileComponent,
    MobileNavbarComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    LazyLoadImageModule,
    SharedModule,
    RouterModule,
    MatRippleModule,
  ],
  exports: [HeaderComponent, MobileNavbarComponent],
  providers: [NavbarStore, ModalService],
})
export class HeaderModule {}
