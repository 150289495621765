<div class="main-wrapper d-flex flex-column">
  <app-mobile-navbar
    class="mobile-navbar"
    [class.opened]="menuOpen"
  ></app-mobile-navbar>

  <div class="body-wrap">
    <app-header></app-header>

    <div
      class="mobile-navbar-overlay"
      *ngIf="menuOpen"
      (click)="toggleMobileNavbar()"
    ></div>

    <div class="main-routing-wrapper">
      <!-- Banners -->
      <!-- <app-web-left-side-promotion></app-web-left-side-promotion> -->
      <div class="wrapper">
        <div class="w-100">
          <!-- <app-web-top-promotion></app-web-top-promotion> -->
        </div>
      </div>
      <!-- <app-web-right-side-promotion></app-web-right-side-promotion> -->
      <div class="popup-promotion">
        <!-- <app-web-popup-promotion></app-web-popup-promotion> -->
      </div>
      <!-- /Banners -->

      <router-outlet></router-outlet>
    </div>

    <div class="mt-auto">
      <app-footer></app-footer>
    </div>
  </div>
</div>
<div class="mobile-dialog-overlay"></div>
<app-footer-nav class="footer-nav mobile-screen"></app-footer-nav>

<app-mobile-catfish-promotion
  class="mobile-screen"
></app-mobile-catfish-promotion>

<!-- <div class="scroll-top" [class.visible]="scrollTopVisible" (click)="scrollTop()">
  <i class="fa fa-chevron-up"></i>
</div> -->
