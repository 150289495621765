import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.scss']
})
export class CookiesPolicyComponent implements OnInit {
  showCookiePopup: boolean = true;
  get accepted(): boolean {
    return !!localStorage.getItem('accepted');
  }

  constructor() { }

  ngOnInit(): void {
  }

  accept(): void{
    localStorage.setItem('accepted', 'true');
  }

}
