import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'toDate',
})
export class ToDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(
    value: Date | string | number | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string
  ): string | null {
    if (!value) return null;

    const normalizedDate = dayjs(value).toDate();

    return formatDate(
      normalizedDate,
      format ?? 'dd MMM yyyy, HH:mm',
      locale ?? this.locale,
      timezone ?? null
    );
  }
}
