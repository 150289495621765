<div class="app-modal">
  <div class="modal-head text-center">
    <div class="modal-title">
      <span i18n>პარამეტრების რედაქტირება</span>
    </div>
    <i class="fa fa-times close-modal" (click)="close()"></i>
  </div>
  <div class="modal-body">
    <form class="form" (submit)="update()" #registerForm="ngForm">
      <div class="form-group FormGroup text-center">
        <div class="photo-upload">
          <img
            *ngIf="user.avatar != null || user.avatar_new"
            height="100"
            [src]="checkFBImage(user.avatar)"
          />
          <input
            #fileInput
            type="file"
            (change)="handleInputChange($event)"
            name="avatar"
            accept="image/png, image/jpeg, image/jpg, image/gif"
            [(ngModel)]="avatarFile"
          />
          <span *ngIf="!user.avatar && !user.avatar_new"
            ><ng-container i18n>ატვირთეთ </ng-container><br />
            <ng-container i18n>ფოტო</ng-container>
          </span>
        </div>
        <input name="_method" type="hidden" value="PATCH" />
      </div>

      <div class="form-group">
        <input
          type="text"
          class="form-control"
          i18n-placeholder
          placeholder="სახელი"
          name="first_name"
          [(ngModel)]="user.first_name"
        />
      </div>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          i18n-placeholder
          placeholder="გვარი"
          name="last_name"
          [(ngModel)]="user.last_name"
        />
      </div>

      <app-user-fav-club
        [club]="user.club"
        (clubSelected)="favClubSelected($event)"
      ></app-user-fav-club>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          i18n-placeholder
          placeholder="მომხმარებლის ნიკი"
          name="username"
          minlength="3"
          [(ngModel)]="user.username"
          disabled
        />
      </div>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          i18n-placeholder
          placeholder="მობილური"
          name="phone"
          minlength="9"
          [(ngModel)]="user.phone"
        />
      </div>

      <ng-container i18n
        >თუ არ გსურთ პაროლის შეცვლა, დატოვეთ ცარიელი.</ng-container
      >
      <div class="form-group">
        <input
          type="password"
          class="form-control"
          i18n-placeholder
          placeholder="პაროლი"
          name="newPassword"
          [(ngModel)]="newPassword"
          minlength="6"
        />
      </div>
      <div class="form-group">
        <input
          type="submit"
          class="submit-btn"
          i18n-value
          value="დამახსოვრება"
          [disabled]="registerForm.invalid"
        />
      </div>
    </form>
  </div>

  <app-loader *ngIf="loading"></app-loader>
</div>
