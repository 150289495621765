import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PartnersComponent } from './features/partners/partners.component';
import { CookiesPolicyComponent } from './features/cookies-policy/cookies-policy.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
    data: { key: 'home' },
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
    data: {
      key: 'home',
      breadcrumb: 'Home',
    },
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./features/search-result/search-result.module').then(
        (m) => m.SearchResultModule
      ),
    data: {
      key: 'search',
    },
  },
  {
    path: 'news',
    loadChildren: () =>
      import('./features/news/news.module').then((m) => m.NewsModule),
    data: {
      key: 'news',
    },
  },
  {
    path: 'player',
    loadChildren: () =>
      import('./features/player/player.module').then((m) => m.PlayerModule),
    data: {
      key: 'player',
      breadcrumb: 'Home',
    },
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./features/user/user.module').then((m) => m.UserModule),
    data: {
      key: 'user',
      breadcrumb: 'Home',
    },
  },
  {
    path: 'cxrilebi',
    loadChildren: () =>
      import('./features/statistics-page/statistics-page.module').then(
        (m) => m.StatisticsPageModule
      ),
    data: {
      key: 'statistics-page',
      breadcrumb: 'Home',
    },
  },
  {
    path: 'club',
    loadChildren: () =>
      import('./features/club/club.module').then((m) => m.ClubModule),
    data: {
      key: 'club',
      breadcrumb: 'Home',
    },
  },
  {
    path: 'championship',
    loadChildren: () =>
      import('./features/championship/championship.module').then(
        (m) => m.ChampionshipModule
      ),
    data: {
      key: 'championship',
      breadcrumb: 'Home',
    },
  },
  {
    path: 'category',
    loadChildren: () =>
      import('./features/category/category.module').then(
        (m) => m.CategoryModule
      ),
    data: {
      key: 'category',
      breadcrumb: {
        skip: true,
      },
    },
  },
  {
    path: 'match',
    loadChildren: () =>
      import('./features/match/match.module').then((m) => m.MatchModule),
    data: {
      key: 'match',
      breadcrumb: 'Home',
    },
  },
  {
    path: 'day-news',
    loadChildren: () =>
      import('./features/day-news/day-news.module').then(
        (m) => m.DayNewsModule
      ),
    data: { key: 'day-news', breadcrumb: 'Home' },
  },
  {
    path: 'videos',
    loadChildren: () =>
      import('./features/videos/videos.module').then((m) => m.VideosModule),
    data: { key: 'videos', breadcrumb: 'Home' },
  },
  {
    path: 'livescore',
    loadChildren: () =>
      import('./features/livescores/livescores.module').then(
        (m) => m.LivescoresModule
      ),
    data: { key: 'livescore', breadcrumb: 'Home' },
  },
  {
    path: 'my-teams',
    loadChildren: () =>
      import('./features/my-teams/my-teams.module').then(
        (m) => m.MyTeamsModule
      ),
    data: { key: 'my-teams', breadcrumb: 'Home' },
  },
  {
    path: 'ads',
    loadChildren: () =>
      import('./features/ads/ads.module').then((m) => m.AdsModule),
    data: { key: 'ads', breadcrumb: 'Home' },
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./features/contact/contact.module').then((m) => m.ContactModule),
    data: { key: 'contact', breadcrumb: 'Home' },
  },
  {
    path: 'partners',
    component: PartnersComponent,
    data: { key: 'partners', breadcrumb: 'Home' },
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./features/about-us/about-us.module').then(
        (m) => m.AboutUsModule
      ),
    data: { key: 'about-us', breadcrumb: 'Home' },
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./features/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
    data: { key: 'privacy-policy', breadcrumb: 'Home' },
  },
  {
    path: 'cookie-policy',
    component: CookiesPolicyComponent,
    data: { key: 'cookie-policy', breadcrumb: 'Home' },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { key: 'not-found' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
