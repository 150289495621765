<div class="mobile-nav" [class.open]="menuOpen">
  <div class="nav-items">
    <div
      class="nav-item dropdown"
      *ngFor="let navbarItem of navbarItems; let i = index"
    >
      <div
        matRipple
        *ngIf="navbarItem"
        class="nav-link h-100 d-flex align-items-center"
        (click)="openChildCats(i, $event, navbarItem)"
      >
        <img
          class="nav-link-icon"
          [lazyLoad]="navbarItem.icon"
          *ngIf="navbarItem.icon"
        />
        {{ navbarItem.name }}
        <i
          *ngIf="navbarItem.subItem.length> 0"
          class="drop-down-arrow fa fa-angle-down"
          [class.rotated]="visibleChildCatsIndex === i"
        ></i>
      </div>

      <div
        *ngIf="navbarItem.subItem"
        class="dropdown-menu"
        [hidden]="visibleChildCatsIndex !== i"
      >
        <div class="dropdown-menu-wrapper">
          <div class="dropdown-menu-section">
            <ng-container *ngFor="let leagues of navbarItem.subItem">
              <a [routerLink]="[leagues.url]">
                <div matRipple class="dropdown-menu-section-item">
                  <img
                    *ngIf="leagues.icon"
                    style="width: 20px; height: 20px; margin-right: 10px;"
                    [src]="leagues.icon"
                  />
                  {{ leagues.name }}
                </div>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
