<div class="footer">
  <div class="footer-top">
    <div class="wrapper">
      <div>
        <div class="footer-nav">
          <a [routerLink]="['/ads/']">
            <div class="footer-link" i18n>რეკლამა</div>
          </a>
          <a [routerLink]="['/contact/']">
            <div class="footer-link" i18n>კონტაქტი</div>
          </a>
          <a [routerLink]="['/partners/']">
            <div class="footer-link" i18n>მეგობარი საიტები</div>
          </a>
        </div>
        <div class="footer-socials">
          <a
            i18n-href
            href="https://www.facebook.com/ONGOAL.GE"
            target="_blank"
          >
            <div class="footer-social">
              <i class="fab fa-facebook-f"></i>
            </div>
          </a>
          <a
            i18n-href
            href="https://www.youtube.com/channel/UCAerhmIZRfaThKrRPCGb9fA"
            target="_blank"
          >
            <div class="footer-social">
              <i class="fab fa-youtube"></i>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-bottom">
    <div class="wrapper h-100">
      <div class="footer-logo"></div>
      <div class="footer-text" i18n>
        2010-2021 "გოლ.ჯი". საიტზე განთავსებული მასალების (სტატიები, ფოტოები,
        დიზაინის ელემენტები) გამოყენება აკრძალულია!
      </div>
    </div>
  </div>
</div>
