<div class="header">
  <div class="wrapper h-100 d-flex align-items-center">
    <div class="col text-left">
      <a [routerLink]="['/']">
        <img
          i18n-src="@@header-logo-resource"
          src="assets/img/logo.png"
          i18n-alt
          alt="goal.ge"
          title="goal.ge"
          class="logo"
        />
      </a>
    </div>
    <div class="col text-right">
      <div class="d-flex h-100 align-items-center justify-content-end">
        <app-search class="desktop-screen"></app-search>
        <div class="mobile-search mobile-screen">
          <div matRipple class="search" (click)="toggleSearch(true)">
            <i class="fa fa-search search-icon" aria-hidden="true"></i>
          </div>
        </div>

        <div class="auth" *ngIf="!userLoggedIn" (click)="auth()">
          <div class="auth-name" i18n>ავტორიზაცია</div>
          <div class="auth-icon"></div>
        </div>
        <div class="user" *ngIf="userLoggedIn && user">
          <div
            class="user-icon"
            [class.no-avatar]="!user.avatar"
            [routerLink]="['/user/', user.id]"
          >
            <img
              *ngIf="user.avatar"
              class="user-avatar"
              alt="user's avatar"
              title="user's avatar"
              [src]="getAvatar(user.avatar)"
            />
          </div>
          <a class="user-name" [routerLink]="['/user/', user.id]">{{
            user.username
          }}</a>
          <div class="logout" (click)="logout()"></div>
        </div>
        <div matRipple class="burger-nav mobile-screen" (click)="toggleMenu()">
          <i class="fa fa-bars"></i>
        </div>
      </div>
    </div>
  </div>
  <app-search-mobile
    *ngIf="searchOpen"
    (searchClosed)="toggleSearch(false)"
  ></app-search-mobile>
</div>
<app-navbar></app-navbar>
