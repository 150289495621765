import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { NewsService } from '@goal-front/shared';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { getIdFromSlug } from '../utils/get-id-from-slug';
import { of } from 'rxjs';

@Injectable({ providedIn: 'any' })
export class NewsResolver implements Resolve<any> {
  constructor(private newsService: NewsService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const id = parseInt(getIdFromSlug(route.paramMap.get('id')), 10);

    if (isNaN(id)) {
      return of({
        error: 'Invalid news id',
      });
    }

    return this.newsService.getFullNewsById(id).pipe(
      map((news) => {
        return { id, news };
      }),
      catchError((error) => {
        return of({
          error: error.message,
        });
      })
    );
  }
}
