import { environment } from './../../../../environments/environment';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavbarStore } from '../../../stores/navbar.store';
import { MenuService } from '@goal-front/shared';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  navbarItems: any[];
  subItem: any[];

  constructor(
    private navbarStore: NavbarStore,
    private menuHttp: MenuService
  ) {}

  ngOnInit(): void {
    this.getMenuList();
  }

  imageLinkGenerator(id): string {
    return `${environment.storage}/size/timthumb.php?src=/teams/${id}.png&w=17`;
  }

  imageLinkGeneratorCountry(id): string {
    return `${environment.storage}//images/countries/flags/${id}`;
  }

  getMenuList() {
    this.menuHttp.getMenuList().subscribe((res: any) => {
      this.navbarItems = res
        .filter((o) => o.parent === 0)
        .filter((o) => o.location === (this.isMobile ? '2' : '1'));
      this.navbarItems.map(
        (o) => (o.subItem = res.filter((response) => response.parent === o.id))
      );
    });
  }

  get isMobile(): boolean {
    return window.innerWidth < 1024;
  }
}
