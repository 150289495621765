import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DestroyService, MenuService } from '@goal-front/shared';
import { environment } from 'apps/mobile/src/environments/environment';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavbarStore } from '../../../stores/navbar.store';

@Component({
  selector: 'app-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class MobileNavbarComponent implements OnInit {
  navbarItems: any[];
  private menuSub: Subscription;
  menuOpen: boolean;
  visibleChildCatsIndex = -1;
  private routerSub: Subscription;

  constructor(
    private navbarStore: NavbarStore,
    private cd: ChangeDetectorRef,
    private router: Router,
    private readonly destroy$: DestroyService,
    private menuHttp: MenuService
  ) {}

  ngOnInit(): void {
    // this.navbarItems = this.navbarStore.getMobileNavbarItems();
    this.getMenuList();
    this.menuSub = this.navbarStore.mobileMenuOpen$
      .pipe(takeUntil(this.destroy$))
      .subscribe((open) => {
        this.menuOpen = open;
        this.cd.markForCheck();
      });

    //router navigated
    this.routerSub = this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.navbarStore.closeBurger();
        }
      });
  }

  getMenuList() {
    this.menuHttp.getMenuList().subscribe((res: any) => {
      this.navbarItems = res
        .filter((o) => o.parent === 0)
        .filter((o) => o.location === '3');
      this.navbarItems.map(
        (o) => (o.subItem = res.filter((response) => response.parent === o.id))
      );
    });
  }

  ngOnDestroy(): void {
    this.menuSub.unsubscribe();
    this.routerSub.unsubscribe();
  }

  imageLinkGenerator(id): string {
    return `${environment.storage}/size/timthumb.php?src=/teams/${id}.png&w=48`;
  }

  imageLinkGeneratorCountry(id): string {
    if (id.includes('.svg'))
      return `${environment.storage}/images/countries/flags/${id}`;
    return `${environment.storage}/size/timthumb.php?src=/images/countries/flags/${id}&w=48`;
  }

  openChildCats(index: number, e: Event, item: any) {
    if (item.subItem.length > 0) {
      e.stopPropagation();
      e.preventDefault();

      if (this.visibleChildCatsIndex == index) {
        this.visibleChildCatsIndex = -1;
      } else {
        this.visibleChildCatsIndex = index;
      }
      this.cd.markForCheck();
    } else {
      this.router.navigate([item.url]);
    }
  }
}
