import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { MatchComparePlayersModalComponent } from '../features/match/match-compare-players-modal/match-compare-players-modal.component';
import { UserAuthComponent } from '../features/user/user-auth/user-auth.component';
import { UserEditComponent } from '../features/user/user-edit/user-edit.component';
import { UserFavoriteModalComponent } from '../features/user/user-favorite-modal/user-favorite-modal.component';

@Injectable()
export class ModalService {
  constructor(private matDialog: MatDialog) {}

  openLoginModal(): any {
    return this.matDialog.open(UserAuthComponent, {
      hasBackdrop: true,
      panelClass: 'user-auth-modal',
      position: {
        top: '0',
      },
    });
  }

  openEditProfileModal(): any {
    return this.matDialog.open(UserEditComponent, {
      hasBackdrop: true,
      position: {
        top: '0',
      },
    });
  }

  openFavoritesModal(): any {
    return this.matDialog.open(UserFavoriteModalComponent, {
      hasBackdrop: true,
      position: {
        bottom: '0',
      },
    });
  }

  openComparePlayersModal(
    isMobile = false,
    players: any[],
    playerToChange?: any
  ) {
    const config: MatDialogConfig = {
      hasBackdrop: true,
      data: {
        players,
        mustChangePlayer: playerToChange,
      },
    };
    config.position = isMobile ? { bottom: '0' } : { top: '0' };
    return this.matDialog.open(MatchComparePlayersModalComponent, config);
  }
}
