import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { CoreService, DestroyService } from '@goal-front/shared';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-user-favorite-search',
  templateUrl: './user-favorite-search.component.html',
  styleUrls: ['./user-favorite-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class UserFavoriteSearchComponent implements OnInit {
  searchValue: string;
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() result: EventEmitter<any> = new EventEmitter();
  private searchValueChange$: Subject<string> = new Subject<string>();

  constructor(
    private coreService: CoreService,
    private cd: ChangeDetectorRef,
    private readonly destroy$: DestroyService
  ) {}

  ngOnInit(): void {
    this.searchValueChange$
      .pipe(
        tap((value) => {
          if (value.length < 3) {
            this.result.emit(null);
          }
        }),
        debounceTime(300),
        distinctUntilChanged(),
        filter((value: string) => value.length >= 3),
        tap(() => {
          this.loading.emit(true);
          this.cd.markForCheck();
        }),
        switchMap(() => {
          return this.coreService.search(this.searchValue).pipe(
            tap(() => {
              this.loading.emit(false);
              this.cd.markForCheck();
            })
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (d) => {
          this.result.emit(d);
          this.cd.markForCheck();
        },
        (error) => console.log(error)
      );
  }

  searchValueChanged(value): void {
    this.searchValueChange$.next(value);
  }
}
