import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpService } from "./http.service";

@Injectable({
    providedIn: 'root'
})

export class MenuService{

    private environment: any;

    constructor(private http1:HttpService, private http:HttpClient, @Inject('env') env: any){this.environment = env;}

    getMenuList(){
      return  this.http.get(`${this.environment.api}menu/front`)
    }

}