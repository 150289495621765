import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  Injector,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PromotionStore, UserService } from '@goal-front/shared';
import { NavbarStore } from './stores/navbar.store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CanonicalService } from './services/canonical.service';

declare let gtag: Function;

@Component({
  templateUrl: './app.component.html',
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = $localize`Goal.ge`;
  menuOpen: boolean;
  routeDataSubscription: Subscription;
  constructor(
    private promotionStore: PromotionStore,
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private dom,
    private injector: Injector,
    private route: ActivatedRoute,
    private canonicalService: CanonicalService,
    private router: Router,
    private userService: UserService,
    private readonly navbarStore: NavbarStore
  ) {
    this.setCanonical();
    if (isPlatformBrowser(this.platformId)) {
      this.initGtag();
      this.promotionStore.init();
      this.userService.checkauthAPI();
    }
  }
  ngOnInit(): void {
    this.subscribeToMobileMenu();
  }

  toggleMobileNavbar() {
    this.navbarStore.toggleBurger();
  }

  private initGtag() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-47112098-1', {
          path: event.url,
        });
      }
    });
  }

  private subscribeToMobileMenu() {
    this.navbarStore.mobileMenuOpen$.subscribe((open) => {
      this.menuOpen = open;
    });
  }

  private setCanonical(): void{
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {        
        if (this.routeDataSubscription) {
          this.routeDataSubscription.unsubscribe();
        }
        const currentRoute = this.findRoute(this.route);
        this.routeDataSubscription = currentRoute.data.subscribe(data => {          
          if (data?.canonical) {
            this.canonicalService.updateCanonicalUrl(data.canonical);
          } else {
            let url = this.router.url;
            if(Object.keys(currentRoute?.snapshot?.params)?.includes('page')){
              url = url.substring(0, url.lastIndexOf("/"));
            }
            this.canonicalService.updateCanonicalUrl(url);
          }
        })
      })
  }

  private findRoute(route: ActivatedRoute): ActivatedRoute {    
    if (route.firstChild) {
      return this.findRoute(route.firstChild);
    } else {
      return route;
    }
  }
}
