<div class="wrapper">
    <div class="w-100 regular-container py-3 px-4">
        <h1 i18n="cookiePolicyTitle">Cookies Policy</h1>

        <p i18n="cookiePolicyLastUpdated">Last updated on 21th of February, 2023</p>

        <p>This Cookies Policy sets out how we use cookies. Please take the time to read and
            understand this Cookies Policy which has been provided for your information, as a user of <span i18n>Goal.ge</span>
            (Website and Apps) (‘You’ or ‘User’), for transparency purposes on our use of cookies.</p>

        <h2 i18n="cookiePolicySection1Title">1. What is a cookie?</h2>

        <p i18n="cookiePolicySection1Text">Cookies are small pieces of text sent by your web browser by a website you
            visit. A cookie file is stored in your browser and allows the Website or a third-party to recognise you and
            make your next visit easier and the Service more useful to you. Cookies do not typically contain any
            information that personally identifies a user, but personal information that we store about you may be
            linked to the information stored in and obtained from cookies. In the event personal data is contained in
            the cookies, the same terms apply as set out in the Privacy Policy. Cookies can be classified as either
            ‘session’ or ‘persistent’, depending on how long they last after they are placed on your browser. Session
            cookies last for as long as you keep your browser open. They expire when you close your browser. Persistent
            cookies expire at a fixed point in time or if you manually delete them from your browser, whichever occurs
            first.</p>
        <h2 i18n="cookies-heading-2">2. What are the types of cookies we use?</h2>
        <p i18n="cookies-paragraph-2.1">2.1. Essential Cookies: Essential cookies help make a website usable by enabling
            basic functions, such as page navigation and access to secure areas of the website. The website cannot
            function properly without these cookies, and are strictly necessary to provide our online service. They can
            also be placed to comply with a legal obligation. Essential cookies are session cookies, and expire once you
            close your browser. Essential Cookies that are used on this Website include the following:</p>
        <ul>
            <li i18n="cookies-list-item-2.1.i">Authentication cookies: These allow us to identify you once you have
                logged in to your account;</li>
            <li i18n="cookies-list-item-2.1.ii">User-input cookies: These allow our website to keep track and remember
                your input when filling online forms;</li>
            <li i18n="cookies-list-item-2.1.iii">User-Interface Customisation cookies: These cookies allow to save your
                language and/or font preferences;</li>
        </ul>
        <p i18n="cookies-paragraph-2.2">2.2. Non-Essential Cookies: these are any cookies that do not fall within the
            definition of essential cookies. Non-essential cookies require Your consent. Such non-essential cookies used
            on this Website include the following:</p>
        <ul>
            <li i18n="cookies-list-item-2.2.i">Analysis cookies: These cookies allow us to improve the website’s
                performance and services based on your activity on our Website;</li>
            <li i18n="cookies-list-item-2.2.ii">Third-party cookies: In certain instances, we use third party cookies,
                which are cookies provided by parties other than Us. We always ensure that these parties are reputable
                and will respect your privacy;
                <ul>
                    <li>Analytic/Performance Cookies: These cookies are set by
                        Google Analytics. Such cookies help us measure how users interact with our Website by collecting
                        information on clicks, time spent on the Website, pages visited on the Website, frequency, which
                        browser is used, and whether a User visits a third-party site. This information is passed on to
                        Google Analytics, and the feedback received is solely used to improve the Website. Statistics
                        and analytical data are useful tools for us to provide a better product for our Users and to
                        make our business predictions more accurate. For further information please visit the Google
                        Analytics Terms of Use and the Google Privacy Policy. You have the option to stop Google
                        Analytics from recognising you when returning to our Website by disabling cookies on your
                        browser. Further information on how this can be done, may be found below.</li>
                    <li>User Experience cookies: These cookies are used in order
                        to improve your experience on our website by fixing any issues that might arise with regards to
                        content or accessibility. We may place social media buttons and/or plugins on the Website that
                        allow you to connect with your chosen social network. In order to work, the social network (such
                        as Facebook, LinkedIn or Twitter) will set cookies through our Website which may then be used to
                        enhance your profile on the social networking site or otherwise contribute to the data they hold
                        for the purposes outlined in their respective privacy policies.</li>
                </ul>
            </li>
        </ul>
        <h2 i18n="cookie-control">3. How to control cookies</h2>
        <p i18n="cookie-control-description">Most web browsers today allow you to control cookies through the settings
            and preferences of your browser of choice. It is possible for you to disable cookies or set your browser to
            alert you when cookies are being sent. You can delete all cookies that are already on your computer and you
            can set your browser to prevent them from being placed. If you do this, however, you may have to manually
            adjust some preferences every time you visit a site and some services and functionalities may not work. To
            find out more about cookies, including how to see what cookies have been set and how to manage and delete
            them, visit <a href="https://www.aboutcookies.org" target="_blank">www.aboutcookies.org</a> or <a
                href="https://www.allaboutcookies.org" target="_blank">www.allaboutcookies.org</a>.</p>
        <p i18n="cookie-control-act">You also have act in the following ways:</p>
        <ol>
            <li i18n="cookie-opt-out">
                <p>Opting-Out</p>
                <p>As part of our commitment to fair information practices, We allow
                    you to opt out of the use of information about your activities on other websites to provide you with
                    Interest-based advertising. You can opt out of Google Analytics by installing Google’s opt-out
                    browser add-on, and out of interest-based Google ads using Google’s Ads Settings. Even if you opt
                    out, you may still receive our advertising. It just means that the advertising you see will not be
                    customised for you.</p>
            </li>
            <li i18n="cookie-refusing">
                <p>Refusing Cookies</p>
                <p>We honor Do Not Track signals and Do Not Track, plant cookies, or
                    use advertising when a Do Not Track (DNT) browser mechanism is in place. You can limit the
                    collection of your information by disabling cookies on your browser. You may also be able to modify
                    your browser settings to require your permission each time a site attempts to set a cookie. However,
                    our website(s) (and many other websites) rely on cookies to enable certain functionality. If you
                    choose to disable cookies, some of the services available on our website may not work properly.</p>
            </li>
            <li>
                <p>Non-<span i18n>Goal.ge</span> Websites and Links</p>
                <p>Our websites may contain links to non- <span i18n>Goal.ge</span>
                    websites. We are not responsible for the practices of those third party websites. Where you access
                    other websites from our sites using the links provided, the operators of these websites may use
                    cookies in accordance with their own cookies policy, which may differ from ours. You should read
                    their privacy and cookie policies carefully before you provide any personal information to them.</p>
            </li>
            <li i18n="cookie-caution">
                <p>Caution regarding automatic Cookie Functionality</p>
                <p>Browsers such as Microsoft Internet Explorer allow you to store
                    passwords and logon IDs so you do not need to re-enter this information each time you access a web
                    site. We strongly urge you NOT to use this functionality to prevent unauthorized use of your online
                    banking access code(s).</p>
            </li>
        </ol>
        <p i18n="cookie-consent">We require your consent for all non-essential cookies, which is acquired by ticking the
            pop-up box upon accessing the website.</p>

        <h2 i18n="cookiePolicyChanges">4. Changes to the Cookies Policy?</h2>

        <p i18n="cookiePolicyChangesText">We may update this Policy from time to time. If we make significant changes,
            we will let you know but please regularly check this Policy to ensure you are aware of the most updated
            version.</p>
        <h2 i18n="cookiePolicyInfoByReg">5. Information collected by registration on the Website or Apps</h2>

        <p i18n="cookiePolicyInfoByRegText">The data subject has the possibility to register on the website of the
            controller with the indication of personal data. Which personal data are transmitted to the controller is
            determined by the respective input mask used for the registration. The personal data entered by the data
            subject is collected and stored for internal use by the controller. The controller may request transfer of
            the data to one or more processors or partners that also uses personal data for an internal purpose which is
            attributable to the controller.
            By registering on the website of the controller, the IP address—assigned by the Internet service provider
            (ISP) and used by the data subject—date, and time of the registration are also stored. The storage of this
            data takes place against the background that this is the only way to prevent the misuse of our services,
            and, if necessary, to make it possible to investigate committed offenses. Insofar, the storage of this data
            is necessary to secure the controller. This data is not passed on to third parties unless there is a
            statutory obligation to pass on the data, or if the transfer serves the aim of criminal prosecution.
            The registration of the data subject, with the voluntary indication of personal data, is intended to enable
            the controller to offer the data subject contents or services that may only be offered to registered users
            due to the nature of the matter in question. Registered persons are free to change the personal data
            specified during the registration at any time, or to have them completely deleted from the data stock of the
            controller.
        </p>

    </div>

</div>

<div class="cookie-policy-popup" *ngIf="!accepted && showCookiePopup">
    <div class="cookie-policy-popup-content">
      <h2>Cookie Policy and Privacy Policy</h2>
      <p>By using this website, you agree to our use of <a routerLink="/cookie-policy">cookies</a> and our <a routerLink="/privacy-policy">privacy policy</a>.</p>
      <button (click)="accept()">Accept</button>
    </div>
  </div>